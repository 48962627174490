// note: IE11 polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Browser_Compatibility
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

// MENU | mobile menu
var menu_button = document.querySelector('.menu__button-mobile')
var menu_dropdown_list = document.querySelector('.menu__list-mobile')

// MENU | toggle button effect
menu_button.addEventListener('click', function () {
  menu_button.classList.toggle('open')
  document.body.classList.toggle('noscroll')
  menu_dropdown_list.classList.toggle('show')
})

// MENU | desktop menu
const dropdown_items = document.querySelectorAll('.menu__item.dropdown')

function toggleMenu(parent, open) {
  const dropdown_content = parent.querySelector(
    '.dropdown__content.dropdown--desktop'
  )
  if (open) {
    dropdown_content.setAttribute('aria-expanded', true)
    dropdown_content.classList.add('open')
  } else {
    dropdown_content.setAttribute('aria-expanded', false)
    dropdown_content.classList.remove('open')
  }
}

dropdown_items.forEach(function (item) {
  // open dropdown when hovering
  item.addEventListener('mouseenter', function () {
    toggleMenu(item, true)
  })
  item.addEventListener('mouseleave', function () {
    toggleMenu(item, false)
  })

  // open dropdown when focusing on dropdown icon (tab navigation, accessibility)
  const icon = item.querySelector('i')
  icon.addEventListener('keyup', function (evt) {
    if (evt.key === 'Enter') {
      toggleMenu(item, true)
    }
  })
})

// FILTER | mobile filters
var filter_opener = document.querySelector('.filters-opener')
var filter_closer = document.querySelector('.filters-closer')
if (filter_opener && filter_closer) {
  var filters_panel = document.querySelector(
    '.template-searchresults .content__main.homepage'
  )
  filter_opener.addEventListener('click', function () {
    filters_panel.classList.add('show')
    filters_panel.setAttribute('aria-expanded', true)
  })
  filter_closer.addEventListener('click', function () {
    filters_panel.classList.remove('show')
    filters_panel.setAttribute('aria-expanded', false)
  })
}

// SEARCH | mobile/desktop search
var search_opener = document.querySelector('.search-opener')

function openSearch() {
  search_panel.setAttribute('aria-expanded', true)
  search_panel.classList.add('show')
  mobile_search_closer.classList.add('show')
}

if (search_opener) {
  var search_closer = document.querySelectorAll('.search-closer')
  var mobile_search_closer = document.querySelector(
    '.search-opener + .search-closer'
  )
  var search_panel = document.querySelector('.search')
  search_opener.addEventListener('click', function () {
    openSearch()
  })

  // open search with tab navigation (only for desktop)
  search_opener.addEventListener('keyup', function (evt) {
    if (evt.key === 'Tab' && window.innerWidth > 1025) {
      openSearch()
    }
  })

  search_closer.forEach(function (element) {
    element.addEventListener('click', function () {
      search_panel.setAttribute('aria-expanded', false)
      search_panel.classList.remove('show')
      mobile_search_closer.classList.remove('show')
    })
  })
}

function createToggle(all_buttons, button_to_make_it_show, target_element) {
  all_buttons.forEach((button) => {
    button.addEventListener('click', function () {
      if (button.id === button_to_make_it_show.id) {
        target_element.classList.add('show')
      } else {
        target_element.classList.remove('show')
      }
    })
  })
}

// REFERRAL FORM | button 'others' - show and hide field 'please specify' based on selection of the button
var button_other = document.querySelector('input[value="other"]')
var all_buttons = document.querySelectorAll('.field__referrer_occupation input')
var formfield_others = document.querySelector('.field__extra_choice')
createToggle(all_buttons, button_other, formfield_others)

// REFERRAL FORM | button 'Yes' - show and hide field 'referral file' based on selection of the referral_file_available button
var referral_button_yes = document.querySelector(
  'input[name="referral_file_available"][value="True"]'
)
var all_referral_buttons = document.querySelectorAll(
  'input[name="referral_file_available"]'
)
var referral_file = document.querySelector('.field__referral_file')
createToggle(all_referral_buttons, referral_button_yes, referral_file)

// GRID_ITEMS | positioning the grid_items in the correct spot according to their 'position' attribute
let grid_items = document.querySelectorAll('.grid__item')
let old_grid = []

for (var i = 0; i < grid_items.length; ++i) {
  old_grid.push(grid_items[i])
}

let new_grid = []

// GRID_ITEMS | put elements with a position at the correct position already
old_grid.map((item) => {
  let position = item.getAttribute('position')
  if (position) {
    new_grid[position - 1] = item
  }
})

// GRID_ITEMS | divides the remaining elements without a position over the rest of the array
old_grid.map((item) => {
  let position = item.getAttribute('position')
  if (!position) {
    for (var i = 0; i < old_grid.length; i++) {
      if (!new_grid[i]) {
        new_grid[i] = item
        i = 100
        break
      }
    }
  }
})

new_grid.map((item) => {
  let parent = document.querySelector('.content__grid')
  if (parent) {
    parent.appendChild(item)
  }
})

// HEIGHT OF CONTENT__MAIN | set the height of content__main to be a multiple of the height of grid__item
// initial values when it loads the page

let mobileScreenWidth = 749

if (window.innerWidth > mobileScreenWidth) {
  resizeContainer()
}

// HEIGHT OF CONTENT__MAIN | set dynamic values when resizing the window
function resizeContainer() {
  let content = document.querySelector('.content')
  let tile = document.querySelector('.grid__item')
  let containerMain = document.querySelector('.content__main')
  if (content && tile && containerMain) {
    let contentHeight = content.offsetHeight + 26
    let tileHeight = tile.offsetHeight + 12
    let remainder = contentHeight % tileHeight
    containerMain.style.height = contentHeight + (tileHeight - remainder) - 12
  }
}

window.addEventListener('resize', function () {
  if (window.innerWidth > mobileScreenWidth) {
    resizeContainer()
  }
})

// Find location
var location_button = document.querySelector('button.find_location_button')
location_button &&
  location_button.addEventListener('click', function (event) {
    var zipcode = document.querySelector('input[name="zipcode"]').value
    event.preventDefault()
    var opts = {
      method: 'GET',
      headers: {},
    }
    fetch('/zip_code_distance/?zip=' + zipcode, opts)
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        var location_list = document.querySelector('.location_list')
        location_list.innerHTML = ''
        var title = document.createElement('h4')
        title.innerHTML = 'Gevonden vestigingen:'
        location_list.appendChild(title)

        Object.keys(json).forEach(function (key) {
          var distance = Math.round(json[key].distance)
          var item = document.createElement('div')
          item.innerHTML =
            '<p><b>' +
            json[key].name +
            '</b> (' +
            distance +
            ' km)<br><a target="_blank" href="/contact/' +
            json[key].slug +
            '">Meer informatie</a> - <a data-id="' +
            json[key].id +
            '">Kies deze vestiging</a><p>'
          location_list.appendChild(item)
        })
      })
  })

document.addEventListener(
  'click',
  function (event) {
    var matches = event.target.matches
      ? event.target.matches('a[data-id]')
      : event.target.msMatchesSelector('a[data-id]')

    if (matches) {
      event.preventDefault()

      // select the correct location
      document.querySelector('[name="location"]').value =
        event.target.dataset['id']

      // hide the picker
      document.querySelector('.find_location').classList.add('hidden')
    }

    var matchesShowPicker = event.target.matches
      ? event.target.matches('a[data-showpicker]')
      : event.target.msMatchesSelector('a[data-showpicker]')

    if (matchesShowPicker) {
      event.preventDefault()

      // show the picker html
      document.querySelector('.find_location').classList.toggle('hidden')
    }
  },
  false
)
